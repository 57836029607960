/* eslint-disable no-return-await */
import NetworkAsync from './network/index.ts';

/**
* Oturum açma
* @param  {object} LoginDTO
* @url `/twebapi/v1.0/Account/Login`
* @ {
* @  `userName`: `string`,
* @  `password`: `string`,
* @ } */
export const signInAPIAsync = async (LoginDTO) => {

  const url = '/twebapi/v1.0/Account/Login';

  return await NetworkAsync.postRequestAsync({ url, data: LoginDTO });

};

/**
 * Sistemde çıkış yapma
 * @url `/shhapi/v1.0/Account/Logout`
*/
export const signOutAPIAsync = async () => {

  const url = '/twebapi/v1.0/Account/Logout';
  
  return await NetworkAsync.getRequestAsync({ url, data: {} });

};


/**
 * Kullanıcı bilgileri apiden getirir
 * @url `/twebapi/v1.0/Account/Info/LoggedUser`
*/
export const getUserInfoByTokenAPIAsync = async () => {

  const url = '/twebapi/v1.0/Account/Info/LoggedUser';
  return await NetworkAsync.getRequestAsync({ url });

};

/**
 * Grup idye göre kullanıcı çekme
*/
export const getUserByGroupIdAPI = async (groupId) => {

  const url = `/twebapi/v1.0/Account/Users/Group/${groupId}`;
  
  return await NetworkAsync.getRequestAsync({ url });

};

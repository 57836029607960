import React from 'react'; 
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import * as $AC from '@actions/Account';
import { useForm } from 'react-hook-form';

export default function HomePage() {

  const { t } = useTranslation();  
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['milva']);

  const { register, handleSubmit, errors } = useForm({ mode: 'all', reValidateMode: 'onChange', });

  const [_showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {

    removeCookie('milva');
  
  }, []);    

  const _handleMouseDownPassword = (event) => {

    event.preventDefault();
  
  }; 

  const _onSubmitAsync = async (form) => {

    await $AC.signInAsync(form, setCookie);

  };
  
  const companyName = 'TWeb';

  const classes = useStyles();

  return (    
    <div className={classes.background}>
      
      <CssBaseline />

      <Container fixed>
        <Typography component="div" className={classes.root}>
          <Grid
            container 
            justify="center" 
            alignItems="center"
            className={classes.root}
          >
            <Grid
              item 
              xs={12} 
              sm={10}
              md={10}
              style={{ margin: 'auto', borderRadius: '0.75rem' }}
            >
              <Paper elevation={3} style={{ borderRadius: '0.75rem' }}>

                <Grid container>

                  <Grid
                    item sm={12} md={5}
                    container justify="center" alignItems="center"
                    className={classes.loginInfo}
                  >
                 
                    <Typography
                      variant="h4" gutterBottom 
                      align="center"
                    >
                      {t('login.info')}
                    </Typography>
                 
                  </Grid>
                  

                  <Grid item sm={12} md={7}>
                    <form onSubmit={handleSubmit(_onSubmitAsync)} className={classes.login}>

                      <div className={classes.loginTitle}>

                        <img 
                          src="/favicon.ico"
                          alt="Milvasoft Logo"
                          width={100}
                          height={100}
                          className={classes.companyLogo}
                        />

                        <Typography
                          variant="h4"
                          gutterBottom 
                          className={classes.companyName}
                          align="center"
                        >
                          {companyName}
                        </Typography>

                      </div>

                      <h3 className={classes.loginText}>{t('login.loginText')}</h3>
                
                      <FormControl className={classes.username} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">{t('login.username')}</InputLabel>
                       
                        <OutlinedInput
                          id="userName"
                          type="text"   
                          startAdornment={(
                            <InputAdornment position="start">
                              <PersonIcon color="primary" />
                            </InputAdornment>
                          )}
                          labelWidth={100}
                          name="userName"
                          inputRef={register({ required: true })}
                        />

                        {errors.userName && (
                          <FormHelperText error id="name-error">
                            { t('helperTexts.required')}
                          </FormHelperText>
                        )}

                      </FormControl>


                      <FormControl className={classes.password} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">{t('login.password')}</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={_showPassword ? 'text' : 'password'}
                          endAdornment={(
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {

                                  setShowPassword(!_showPassword); 

                                }}
                                onMouseDown={_handleMouseDownPassword}
                                edge="end"
                              >
                                {_showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )}
                          labelWidth={70}                          
                          name="password"
                          inputRef={register({ required: true })}
                        />

                        {errors.password && (
                          <FormHelperText error id="password-error">
                            { t('helperTexts.required')}
                          </FormHelperText>
                        )}
                      </FormControl>
                  
                      <div className={classes.loginBottom}>
                    
                        <p>{t('login.forgotPassword')}</p>

                        <Button
                          variant="contained" 
                          color="primary" 
                          className={classes.submitButton}
                          type="submit"
                        >
                          {t('login.loginButton')}
                        </Button>
                  
                      </div>

                    </form>
                  </Grid>

                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Typography>
      </Container>
    </div>
  );

}


const useStyles = makeStyles((theme) => ({
  background: {
    height: '100vh',
    backgroundImage: `url(${'/loginBg.jpg'})`,
    backgroundSize: 'cover',   
  },
  
  root: {
    height: '100vh',    
    minWidth: 350,
  },
  
  loginInfo: {
    backgroundImage: `url(${'/loginBg.jpg'})`,
    backgroundSize: 'cover',
    padding: '80px 40px',
    color: 'white',
    borderTopLeftRadius: '0.75rem',
    borderBottomLeftRadius: '0.75rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: '0',
      borderTopRightRadius: '0.75rem',
      paddingTop: theme.spacing(2),
    },  
    [theme.breakpoints.down('xs')]: {     
      display: 'none',
    },  
  },

  login: {
    padding: '15px 70px',
    [theme.breakpoints.down('xs')]: {     
      padding: '15px 30px',
    },  
  },

  loginTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },

  companyLogo: {
    borderRadius: '50%' 
  },

  companyName: {
    marginLeft: 10,
    color: theme.palette.primary.main,
    fontWeight: ' bold',   
  },

  loginText: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main
  },

  username: {
    marginTop: theme.spacing(2),
    width: '100%',
  },

  password: {
    marginTop: theme.spacing(3),
    width: '100%',
  },

  loginBottom: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },  
  },

  submitButton: {
    width: '35%',
    minWidth: 120
  }

}));

export async function getStaticProps() {

  return {
    props: {},
  };

}

import { dispatcher } from '@src/helpers/redux';
import AxiosInstance from './AxiosInstance';
import { 
  Method, 
  Result,
  ApiParams, 
  NetworkParams 
} from './Abstract';
import { backdropOpen, backdropClose} from '@actions/Global';

// ApiParams Mapping to NetworkParams
export function networkParamsMap(param: ApiParams, method: Method): NetworkParams {  
  const networkObject : NetworkParams = {};  

  Object.assign(networkObject, param);
  
  networkObject.method = method;

  return networkObject;
}

export default class NetworkAsync {
  static network(params: NetworkParams): Promise<Result> {
    const {
      url, method, headers, data, backDrop = true ,isReturnResult
    } = params;
    
    return new Promise((resolve, reject) => {

      backDrop && backdropOpen();

      AxiosInstance({
        url, method, headers, data 
      })
        .then((res) => {

          backDrop && backdropClose();

          // Change it for yourself
          if (res?.data?.statusCode === 200) {
                         
              if(isReturnResult){
                return resolve(res?.data);
              }else{
                return resolve(res?.data?.result);
              }
           
          }
          else{
              return reject(res.data);
          }    
        })
        .catch((err) => {
          
          backDrop && backdropClose();

          return reject(err)
        });
    });
  }

  static getRequestAsync(params: ApiParams): Promise<Result> {
    return this.network(networkParamsMap(params, 'GET'));
  }

  static deleteRequestAsync(params: ApiParams): Promise<Result> {
    return this.network(networkParamsMap(params, 'DELETE'));
  }

  static postRequestAsync(params: ApiParams): Promise<Result> {
    return this.network(networkParamsMap(params, 'POST'));
  }
 
  static putRequestAsync(params: ApiParams): Promise<Result> {
    return this.network(networkParamsMap(params, 'PUT'));
  }
 
  static patchRequestAsync(params: ApiParams): Promise<Result> {
    return this.network(networkParamsMap(params, 'PATCH'));
  }
}

// (function() {
//     String token = store.getState().session.token;
//     if (token) {
//         axios.defaults.headers.common['Authorization'] = token;
//     } else {
//         axios.defaults.headers.common['Authorization'] = null;
//         /*if setting null does not remove `Authorization` header then try     
//           delete axios.defaults.headers.common['Authorization'];
//         */
//     }
// })();

/* eslint-disable no-return-await */
import Router from 'next/router';
import * as $API from '@api/Account';
import AxiosInstance from '@src/api/network/AxiosInstance';
import { globalChange } from './Global';

export const signInAsync = async (LoginDTO, setCookie) => {

  await $API.signInAPIAsync(LoginDTO)
    .then(async (res) => {

      const { token } = res;

      AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
      
      // TODO Birden fazla group var ise direkt grup sayfası yok ise grup seçme
   
      await getUserInfoByTokenAsync().then((response) => {
        
        const user = response;
        
        if (user?.userGroups.length > 1) {

          Router.push('/groupSelected');

        } else {

          user.selectedGroup = user?.userGroups[0];
          
          Router.push('/home');
        
        }
        
        globalChange({ property: 'user', value: user });

        setCookie('milva', { token: token.accessToken, user }, {
          path: '/',
          maxAge: '86400', // Expires after 1hr
          sameSite: true,
        });
      
      });
    
    }).catch(() => { });

};

export const signOutAsync = async () => {

  await $API.signOutAPIAsync().then(() => { }).catch(() => { });

  AxiosInstance.defaults.headers.common.Authorization = null;

  globalChange({ property: 'user', value: {} });
  
  Router.push('/');

};

export const getUserInfoByTokenAsync = async () => await $API.getUserInfoByTokenAPIAsync();
